import React, { useEffect, useState, forwardRef } from "react";
import "./checkout.css";

const SelectRegion = forwardRef(
  (
    {
      country,
      state,
      city,
      names,
      labels,
      onChange,
      countryError,
      countryType,
      setError,
      flag = true,
      flag1 = true,
    },
    ref
  ) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
      fetch("https://api.countrystatecity.in/v1/countries", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSCAPI-KEY":
            "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (country) {
            let obj = result.find((e) => {
              return e.name == country;
            });
            if (obj) {
              updateCountry(
                {
                  target: {
                    value: `${obj.iso2},,${country}`,
                    name: names[0],
                  },
                },
                false
              );
            }
          }
          setCountries(result);
        })
        .catch((err) => {});
    }, []);

    function updateCountry(e, flag = true) {
      const { name, value } = e.target;
      onChange(e);
      setError((prev) => ({ ...prev, [name]: "" }));
      if (flag == true && flag1 == true) {
        onChange({
          target: {
            name: name === "country" ? "state" : "nativeState",
            value: "",
          },
        });
        onChange({
          target: {
            name: name === "country" ? "city" : "nativeCity",
            value: "",
          },
        });
      }
      const countryCode = value.split(",,")[0];
      fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSCAPI-KEY":
              "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setStates(result);
          if (state) {
            let stateObject = result.find((e) => {
              return e.name === state;
            });
            if (stateObject) {
              updateState(
                {
                  target: {
                    value: stateObject?.iso2 + ",," + stateObject.name,
                    name: names[1],
                  },
                },
                countryCode
              );
            }
          }
        });
    }

    function updateState(e, code) {
      const { name, value } = e.target;
      onChange(e);
      setError((prev) => ({ ...prev, [name]: "" }));
      if (!code) {
        onChange({
          target: { name: name === "state" ? "city" : "nativeCity", value: "" },
        });
      }

      const stateCode = value.split(",,")[0];
      const countryCode = code ? code : country.split(",,")[0];
      setError((prev) => ({ ...prev, [name]: "" }));
      fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSCAPI-KEY":
              "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => setCities(result));
    }

    return (
      <>
        <select
          className="border-bottom-0 form-select select-radius py-2 input-field"
          value={country}
          onChange={updateCountry}
          ref={ref[0]} // Applying ref to the select element
          name={names[0]}
        >
          <option value="" disabled selected>
            Select Country
          </option>
          {countries.map((country, index) => (
            <option value={`${country.iso2},,${country.name}`} key={index}>
              {country.name}
            </option>
          ))}
        </select>

        {countryError && (
          <p className="small mb-0 text-danger mt-1">
            {countryType} is required
          </p>
        )}

        {flag1 == true && (
          <select
            className="form-select select-radius border-bottom-0 py-2 input-field"
            value={state}
            name={names[1]}
            onChange={updateState}
            ref={ref[1]}
          >
            <option value="" disabled selected>
              Select State
            </option>
            {states?.length ? (
              states?.map((state, index) => (
                <option value={state.iso2 + ",," + state.name} key={index}>
                  {state.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Select State
              </option>
            )}
          </select>
        )}
        {flag1 == true && (
          <select
            className="form-select py-2 border-bottom-0 select-radius input-field"
            value={city}
            name={names[2]}
            onChange={onChange}
            ref={ref[2]}
          >
            <option value="" disabled selected>
              Select City
            </option>
            {cities.length ? (
              cities.map((city, index) => (
                <option key={index} value={city.name}>
                  {city.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Cities Available
              </option>
            )}
          </select>
        )}
      </>
    );
  }
);

export default SelectRegion;
